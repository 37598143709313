import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { Center } from '@chakra-ui/react';

import { Loader } from '@asa/asasqt-microfrontend-components/dist/layout/animate/Loader';

const Index = () => {
  const router = useRouter();
  const appName = 'ServiceApp';

  useEffect(() => {
    router.push('/auth');
  }, [router]);

  return (
    <>
      <Head>
        <title>{appName}</title>
      </Head>
      <Center flex="1">
        <Loader />
      </Center>
    </>
  );
};
export default Index;
